import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import registerSW from "./registerServiceWorker";

import { IonicVue } from "@ionic/vue";

import { StoragePlugin } from "./plugins/storage";
import { SettingsPlugin } from "./plugins/settings";
import { HistoryPlugin } from "./plugins/history";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
/* Theme variables */
import "./theme/fonts.css";

import "./assets/globals.scss";

import "./theme/variables.css";

registerSW();
const app = createApp(App).use(IonicVue).use(router).use(StoragePlugin);

app.use(SettingsPlugin);
app.use(HistoryPlugin);

router.isReady().then(() => {
  app.mount("#app");
});
