import { App, Plugin, reactive } from "vue";
import localforage from "localforage";

//import { StorageInterface } from "@/plugins/storage";
//const storage = inject("storage") as StorageInterface;

interface stringByAny {
  [setting: string]: any;
}

interface SettingsInterface {
  settingToComputed(key: string): any;
  getSetting(key: string): any;
}

// The Install function used by Vue to register the plugin
export const SettingsPlugin: Plugin = {
  install(app: App) {
    const storage = localforage.createInstance({
      name: "mental-math",
    });

    console.log("app", app);
    const cs: stringByAny = {
      difficulty: "easy",
      congratulate: true,
      mode: "simple",
      actionSeqAmount: 1,
      seqLength: 6,
    };

    const cachedSettings = reactive(cs);

    const updateSettings = (newSettings: object) => {
      Object.assign(cachedSettings, newSettings);
      storage.setItem("settings", { ...cachedSettings });
    };

    const settingToComputed = (setting: string) => {
      return {
        get() {
          return cachedSettings[setting];
        },
        set(newValue: any) {
          updateSettings({ [setting]: newValue });
        },
      };
    };

    const getSetting = (setting: string) => {
      return cachedSettings[setting];
    };

    const init = async () => {
      const c = await storage.getItem("settings");
      if (c) {
        Object.assign(cachedSettings, c);
      }
    };

    init();

    app.provide("settings", { settingToComputed, getSetting });
  },
};

export { SettingsInterface };
