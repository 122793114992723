import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import NavigationLayout from "../views/NavigationLayout.vue";
import HelloView from "../views/HelloView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: NavigationLayout,
    redirect: "/train",
    children: [
      {
        path: "/train",
        component: () => import("@/views/trainPage.vue"),
      },
      {
        path: "/settings",
        component: () => import("@/views/settingsPage.vue"),
      },
      {
        path: "/insights",
        component: () => import("@/views/insightsPage.vue"),
      },
      {
        path: "/privacy",
        component: () => import("@/views/privacyPage.vue"),
      },
    ],
  },

  {
    path: "/hello",
    component: HelloView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
