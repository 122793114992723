import { App, Plugin, reactive } from "vue";
import { openDB, deleteDB, wrap, unwrap, DBSchema, IDBPDatabase } from "idb";
import { makeID } from "@/utils/tgmCommonLite";

interface stringByAny {
  [setting: string]: any;
}

interface HistoryInterface {
  add(
    type: string,
    info: object,
    answer: number,
    valid: boolean | number | string
  ): void;
  getSorted(): Promise<HistoryEntry[]>;
  clear(): Promise<void>;
  onChange(callback: () => void): void;
}

interface HistoryEntry {
  uid: string;
  date: string;
  type: string;
  info: { display: number[] | string[]; data: object };
  valid: boolean | number | string;
  answer: string | number;
}

// The Install function used by Vue to register the plugin
export const HistoryPlugin: Plugin = {
  install(app: App) {
    let db: IDBPDatabase;

    const init = async () => {
      db = await openDB("mentalMathIDB", 1, {
        async upgrade(db, oldVersion, newVersion, transaction) {
          console.log(db, oldVersion, newVersion);

          const objectStore = db.createObjectStore("history", {
            keyPath: "uid",
            //   autoIncrement: true,
          });
          await objectStore.createIndex("date", "date", { unique: false });
          //  await objectStore.createIndex("solved", "solved", { unique: false });

          await transaction.done;
        },
      });
    };

    const changeCallbacks = <{ (): void }[]>[];

    const onChange = (cb: { (): void }) => {
      changeCallbacks.push(cb);
    };

    const runCallbacks = () => {
      for (const cb of changeCallbacks) {
        cb();
      }
    };

    const add = async (
      type: string,
      info: object,
      answer: number,
      valid: boolean | number | string
    ) => {
      const tx = db.transaction("history", "readwrite");
      //const store = tx.objectStore("history");

      await tx.store.add(
        JSON.parse(
          JSON.stringify({
            date: new Date(),
            valid,
            info,
            answer,
            type,
            uid: makeID(10),
          })
        )
      );

      await tx.done;

      runCallbacks();
    };

    const getSorted = async (): Promise<HistoryEntry[]> => {
      const result = [];

      const tx = db.transaction("history", "readonly");
      let cursor = await tx.store.index("date").openCursor(null, "prev");
      while (cursor) {
        result.push(cursor.value);
        cursor = await cursor.continue();
      }
      await tx.done;

      return result;
    };

    const clear = async () => {
      const tx = db.transaction("history", "readwrite");
      await tx.store.clear();
      await tx.done;
    };

    init();

    app.provide("history", { add, getSorted, clear, onChange });
  },
};

export { HistoryInterface, HistoryEntry };
