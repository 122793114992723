import localforage from "localforage";
import { App, Plugin } from "vue";

// The Install function used by Vue to register the plugin
export const StoragePlugin: Plugin = {
  install(app: App) {
    const storage = localforage.createInstance({
      name: "mental-math",
    });
    app.provide("storage", storage);
  },
};

interface StorageInterface {
  getItem(key: string): any;
  setItem(key: string, value: any): any;
}

export { StorageInterface };
